<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm">
      <h2 class="ticket-title">Редактировать жителя</h2>
      <div class="close" @click="closeModal">&#10005;</div>
      <div class="add-resident">
        <div class="address-row">
          <div class="address">
            <div class="type">Блок</div>
            <div class="num">{{ resident.block.number }}</div>
          </div>
          <div class="address">
            <div class="type">Подъезд</div>
            <div class="num">{{ resident.entrance }}</div>
          </div>
          <div class="address">
            <div class="type">Этаж</div>
            <div class="num">{{ resident.apt.floor }}</div>
          </div>
          <div class="address">
            <div class="type">Квартира</div>
            <div class="num">{{ resident.apt.number }}</div>
          </div>
        </div>
        <v-form class="add-resident" :disabled="submitEditOwner">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="formData.first_name"
                :rules="newResidentRules"
                label="Имя"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.last_name"
                :rules="newResidentRules"
                label="Фамилия"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.email"
                :rules="emailRules"
                label="E-mail"
                type="email"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.phone"
                :rules="phoneRules"
                v-mask="'+# (###) ###-##-##'"
                placeholder="+7 (___) ___-__-__"
                label="Телефон"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-select
                v-model="formData.provider"
                :rules="newResidentRules"
                :items="providersList"
                item-text="title"
                item-value="id"
                label="Тип лицевого счёта"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="formData.personal_account"
                :rules="accountRules"
                v-mask="'#########'"
                label="Лицевой счёт"
                type="number"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="error" @click="closeModal"> Сбросить </v-btn>
            <v-btn
              @click="updateResident"
              :disabled="submitEditOwner"
              :loading="submitEditOwner"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  addBuildingOwner,
  updateBuildingOwner,
  updatePersonalAccount,
} from "@/api/buildings";

export default {
  props: {
    resident: {
      type: Object,
      default: () => ({}),
    },
    providersList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      submitEditOwner: false,
      formData: {
        first_name: this.resident.personal_account.owner.split(" ")[1],
        last_name: this.resident.personal_account.owner.split(" ")[0],
        phone: this.resident.building_owner?.phone || "",
        email: this.resident.building_owner?.email || "",
        personal_account: this.resident.personal_account.personal_account,
        provider: this.resident.personal_account.provider,
        address: this.resident.block.complex.address,
        file: null,
      },
      newResidentRules: [(v) => !!v || "Обязательное поле"],
      phoneRules: [
        (v) => !v || v.length === 18 || "Неправильный формат номера",
      ],
      emailRules: [
        (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || !v || "Неправильный формат E-mail";
        },
      ],
      accountRules: [
        (v) => !!v || "Обязательное поле",
        (v) => !v || v.length >= 6 || "От 6 до 9 символов",
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async updateResident() {
      try {
        this.submitEditOwner = true;
        let account_payload = {
          old_personal_account:
            this.resident.apt.personal_account.personal_account,
          personal_account: this.formData.personal_account,
          provider: this.formData.provider,
          last_name: this.formData.last_name,
          first_name: this.formData.first_name,
        };

        if (
          this.resident.building_owner?.phone === undefined ||
          this.resident.building_owner?.phone === ""
        ) {
          if (this.formData.phone && this.formData.phone !== "") {
            let owner_payload = {
              building: this.resident.apt.apartment_id,
              first_name: this.formData.first_name,
              last_name: this.formData.first_name,
              phone: this.formData.phone.replace(/[^0-9.]/g, ""),
              email: this.formData.email,
            };

            await addBuildingOwner(owner_payload);
            await updatePersonalAccount(account_payload);
            this.$emit("closeModal");
            this.$emit("closeResident");
          }
        } else {
          if (this.formData.phone !== "") {
            let owner_payload = {
              building: this.resident.apt.apartment_id,
              first_name: this.formData.first_name,
              last_name: this.formData.first_name,
              phone: this.formData.phone.replace(/[^0-9.]/g, ""),
              email: this.formData.email,
            };
            if (
              this.resident.building_owner.phone ===
              this.formData.phone.replace(/[^0-9.]/g, "")
            ) {
              delete owner_payload.phone;
            }
            if (this.resident.building_owner.email === this.formData.email) {
              delete owner_payload.email;
            }

            await updateBuildingOwner(owner_payload);
            await updatePersonalAccount(account_payload);
            this.$emit("closeModal");
            this.$emit("closeResident");
          } else {
            this.$store.commit('SHOW_SNACKBAR', 'editOwnerErrorSnackbar');
            this.$store.commit('SET_SNACKBAR_TITLE', 'Номер телефона не может быть пустым!');
            this.$store.commit('SET_SNACKBAR_COLOR', 'error');
          }
        }
        this.$store.commit('SHOW_SNACKBAR', 'editOwnerSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Житель успешно обновлён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'editOwnerErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при редактировании жителя');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.submitEditOwner = false;
      };
    },
  },
};
</script>

<style>
.personal-account-frame {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  margin-bottom: 12px;
  border: 1px solid #f0f0f3;
  border-radius: 8px;
  outline: none;
}
.personal-account-frame select {
  text-align: center;
  border: none;
  height: 46px;
}

.personal-account-frame select:focus-visible {
  outline: none;
}
.personal-account-frame input {
  height: 46px;
  padding: 0;
  margin: 0;
  border: none;
  padding-left: 10px;
}

.personal-account-frame {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.personal-account-frame:nth-child(1) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: center;
}

.personal-account-frame:nth-child(2) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.is-invalid {
  border-color: #ff7171;
}
</style>
